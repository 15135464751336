import React from "react";
import "./card.css";
import { connect } from "react-redux";
import { selectTheme, selectUser } from "../../ducks";

function createMarkup(html) {
  return { __html: html };
}

const Card = ({ item, theme, user }) => {
  const { description, title, image, externalLink } = item;
  const { borderRadius, showText, boxShadow } = theme.card;
  return (
    <div
      className="card"
      style={{ borderRadius: borderRadius, boxShadow: boxShadow }}
    >
      <a href={externalLink} target="_blank" rel="noreferrer">
        <img
          src={`https://graphsby.s3-ap-southeast-2.amazonaws.com/${user.slug}/${image}.jpg`}
          alt={title}
          className="item-image"
          style={{ borderRadius: `${borderRadius}px ${borderRadius}px 0 0` }}
        />
        {showText && (
          <div className="container">
            <h4>{title}</h4>
            <p
              className="user-added-text"
              style={{ fontWeight: 200 }}
              dangerouslySetInnerHTML={createMarkup(description)}
            />
          </div>
        )}
      </a>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    theme: selectTheme(state),
    user: selectUser(state),
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Card);
