import React from "react";
import { RegularCard, ArtCard } from "../card";
import "./item-area.css";
import { connect } from "react-redux";
import { selectActiveItems, selectTheme } from "../../ducks";
import Filters from "../filters";

const Card = ({ themeName, ...props }) => {
  const { item, key } = props;
  switch (themeName) {
    case "artist":
      return <ArtCard item={item} key={key} />;
    default:
      return <RegularCard item={item} key={key} />;
  }
};

const ItemArea = ({
  items,
  filters,
  activeFilters,
  setActiveFilters,
  activeItems,
  theme,
}) => {
  const { filtersPosition } = theme.app;
  const { name } = theme;
  const hasTopFilters = filtersPosition === "top";
  return (
    <div id="item-area" style={{ marginTop: 25 }}>
      {hasTopFilters && <Filters />}

      <div id="projects-area">
        {items && activeItems && (
          <>
            {activeItems.map((item, i) => {
              return <Card key={i} item={item} themeName={name} />;
            })}
          </>
        )}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    items: state.items,
    activeItems: selectActiveItems(state),
    theme: selectTheme(state),
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ItemArea);
