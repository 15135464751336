import React from "react";
import { connect } from "react-redux";
import "./filters.css";
import {
  selectTheme,
  selectUser,
  selectFilters,
  setActiveFilters as _setActiveFilters,
} from "../../ducks";
import useMediaQuery from "../../hooks/use-media-query";

const Filters = ({ filters, setActiveFilters, theme }) => {
  const { isTablet } = useMediaQuery();
  const { filtersPosition } = theme.app;
  const hasTopFilters = filtersPosition === "top";
  const { button } = theme.filterStyle;
  return (
    <div
      className="filter-button-area"
      style={{
        textAlign: "right",
        display: isTablet ? "flex" : "block",
        flexWrap: hasTopFilters ? "wrap" : "no-wrap",
        flexDirection: hasTopFilters ? "row" : "column",
      }}
    >
      {filters.map((filter, i) => {
        return (
          <button
            key={i}
            onClick={() => {
              setActiveFilters(filter);
            }}
            className={`filter-button`}
            style={{
              display: hasTopFilters ? "inline-block" : "block",
              textAlign: hasTopFilters ? "center" : "left",
              marginBottom: 10,
              borderRadius: button.borderRadius,
              width: button.width,
              backgroundColor: filter.highlighted
                ? button.selectedColor
                : "white",
            }}
          >
            <div
              className="filter-inner"
              style={{ borderRadius: button.borderRadius }}
            >
              {filter.label}
            </div>
          </button>
        );
      })}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    theme: selectTheme(state),
    filters: selectFilters(state),
    user: selectUser(state),
  };
};

const mapDispatchToProps = {
  setActiveFilters: _setActiveFilters,
};

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
