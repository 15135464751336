import React, { useState, useEffect } from "react";
import "./contact-form.css";
import { connect } from "react-redux";
import {
  selectCurrentSiteUrl,
  selectEmailAlertMessage,
  selectTheme,
  selectUser,
  sendEmail as _sendEmail,
  setEmailAlertMessage as _setEmailAlertMessage,
} from "../../ducks";
import LoadingNotice from "../loading-notice/LoadingNotice";

const initialState = {
  name: "",
  email: "",
  subject: "",
  message: "",
};

const ContactForm = ({
  sendEmail,
  emailAlertMessage,
  setEmailAlertMessage,
  user,
  currentSiteUrl,
  theme,
}) => {
  const [emailData, setEmailData] = useState(initialState);
  const [sending, setSending] = useState(false);

  const { app } = theme;

  // Clear fields if email was sent
  useEffect(() => {
    if (emailAlertMessage.message) {
      setSending(false);
    }
    if (emailAlertMessage.success) {
      setEmailData(initialState);
    }
  }, [emailAlertMessage]);

  const dispatchEmail = (event) => {
    event.preventDefault();
    setEmailAlertMessage({});
    setSending(true);
    sendEmail(emailData, user.id, currentSiteUrl);
  };

  const onInputChange = (event) => {
    const { name, value } = event.target;

    setEmailData({
      ...emailData,
      [name]: value,
    });
  };

  const fields = [
    {
      type: "text",
      name: "name",
      label: "Full Name",
      key: "name",
      placeholder: "Enter your full name",
    },
    {
      type: "text",
      name: "email",
      label: "Email",
      key: "email",
      placeholder: "Enter your email",
    },
    {
      type: "text",
      name: "subject",
      label: "Subject",
      key: "subject",
      placeholder: "Enter subject",
    },
  ];

  return (
    <div id="contact-form-wrapper">
      <form onSubmit={dispatchEmail}>
        {emailAlertMessage.message && (
          <p className={`alert-message success-${emailAlertMessage.success}`}>
            {emailAlertMessage.message}
          </p>
        )}
        {sending && <LoadingNotice loadingText={"Sending"} />}

        {fields.map((field) => {
          const { type, name, label, key, placeholder } = field;
          return (
            <div className="form-group" key={key}>
              <label htmlFor="name">{label}</label>
              <input
                style={{ borderRadius: app.borderRadius, borderWidth: 1 }}
                type={type}
                name={name}
                value={emailData[key]}
                placeholder={placeholder}
                onChange={onInputChange}
              />
            </div>
          );
        })}

        <div className="form-group">
          <label htmlFor="message">Message</label>
          <textarea
            style={{ borderRadius: app.borderRadius, borderWidth: 1 }}
            id="message-textarea"
            name="message"
            value={emailData.message}
            rows="3"
            placeholder="Enter your message"
            onChange={onInputChange}
          />
        </div>
        <div>
          <button
            className="btn-submit"
            type="submit"
            style={{ borderRadius: app.borderRadius, borderWidth: 1 }}
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    emailAlertMessage: selectEmailAlertMessage(state),
    user: selectUser(state),
    currentSiteUrl: selectCurrentSiteUrl(state),
    theme: selectTheme(state),
  };
};

const mapDispatchToProps = {
  sendEmail: _sendEmail,
  setEmailAlertMessage: _setEmailAlertMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactForm);
