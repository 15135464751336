import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import reducers from "./ducks";

const finalCreateStore = compose(
  applyMiddleware(thunk),
  window.__REDUX_DEVTOOLS_EXTENSION__
    ? window.__REDUX_DEVTOOLS_EXTENSION__()
    : (f) => f
)(createStore);

const store = finalCreateStore(reducers);
if (process.env.NODE_ENV === "development" && module.hot) {
  module.hot.accept("./ducks", () => {
    const newRootReducer = require("./ducks").default;
    store.replaceReducer(newRootReducer);
  });
}

export default store;
