import React from "react";
import "./sidebar.css";
import { connect } from "react-redux";
import { selectTheme, selectUser } from "../../ducks";
import Filters from "../filters";
import Profile from "../profile";

const Sidebar = ({ theme, user }) => {
  const { filtersPosition, borderRadius, boxShadow } = theme.app;
  const hasSidebarFilters = filtersPosition === "left";
  return (
    <div
      id="info-area"
      style={{ borderRadius: borderRadius, boxShadow: boxShadow }}
    >
      {hasSidebarFilters ? <Filters /> : <Profile />}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    theme: selectTheme(state),
    user: selectUser(state),
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
