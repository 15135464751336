import React from "react";
// import { connect } from "react-redux";
import { useParams } from "react-router-dom";
// import { selectItemBySlug } from "../../ducks";
import Item from "./Item";

const Page = () => {
  let { page } = useParams();
  return <Item slug={page} />;
};

export default Page;
