import React, { useEffect } from "react";
import "./App.css";
import MainView from "./components/main";
import {
  fetchData as _fetchData,
  setCurrentSiteUrl as _setCurrentSiteUrl,
  selectFilters,
  selectTheme,
  selectUser,
} from "./ducks";
import { connect } from "react-redux";
import ReactGA from "react-ga";

function App({ fetchData, theme, filters, user, setCurrentSiteUrl }) {
  const currentSiteUrl = window.location.href;
  const baseUrl = currentSiteUrl.replace(/https?:\/\//gi, "").split("/")[0];

  useEffect(() => {
    fetchData(baseUrl);
  }, [fetchData, baseUrl]);

  useEffect(() => {
    if (user && user.googleAnalyticsId) {
      ReactGA.initialize(user.googleAnalyticsId);
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }, [user]);

  return (
    <div className="App">
      {theme && filters && user && <MainView currentSiteUrl={currentSiteUrl} />}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    theme: selectTheme(state),
    filters: selectFilters(state),
    user: selectUser(state),
  };
};

const mapDispatchToProps = {
  fetchData: _fetchData,
  setCurrentSiteUrl: _setCurrentSiteUrl,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
