import React from "react";
import Navbar from "../navbar";
import Sidebar from "../sidebar";
import ItemArea from "../item-area";
import ContactForm from "../contact-form";
import { connect } from "react-redux";
import "./main-view.css";
import { selectTheme, selectUser } from "../../ducks";
import useMediaQuery from "../../hooks/use-media-query";
import { Helmet } from "react-helmet";
import { createBrowserHistory } from "history";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import Page from "../page";

export const history = createBrowserHistory();

const MainView = ({ showingPage, fetchData, theme, user, currentSiteUrl }) => {
  const { isMobile } = useMediaQuery();
  const { backgroundColor } = theme.app;

  return (
    <div style={{ backgroundColor: backgroundColor }}>
      <Helmet>
        <title>{user.title}</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content={user.meta || user.title} />
        <style>{`
          body {
            background-color: ${backgroundColor}
          }`}</style>
        <meta property="og:image" content={user.ogImage} />
      </Helmet>
      <BrowserRouter>
        <Navbar />
        <div id="wrapper">
          <Switch>
            <Route path="/" exact>
              <div id="grid-wrapper">
                {!(isMobile && showingPage === "contact") && <Sidebar />}
                <div id="content-area">
                  {showingPage === "contact" ? <ContactForm /> : <ItemArea />}
                </div>
              </div>
            </Route>
            <Route path="/contact">
              <div id="content-area">
                <ContactForm />
              </div>
            </Route>
            <Route path="/:page">
              <Page />
            </Route>
          </Switch>
        </div>
      </BrowserRouter>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    showingPage: state.showingPage,
    theme: selectTheme(state),
    user: selectUser(state),
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MainView);
