import React from "react";
import "./page.css";
import { connect } from "react-redux";
import { selectItemBySlug, selectUser } from "../../ducks";

function createMarkup(html) {
  return { __html: html };
}

const Item = ({ item, user }) => {
  if (!item) {
    return <div>Not found</div>;
  }
  return (
    <div style={{ width: "100%" }}>
      <div className="item-wrapper">
        <h1>{item.title}</h1>
        {item.image && (
          <img
            width={"100%"}
            src={`https://graphsby.s3-ap-southeast-2.amazonaws.com/${user.slug}/${item.image}.jpg`}
            alt={item.title}
          ></img>
        )}
        <div dangerouslySetInnerHTML={createMarkup(item.fullText)} />
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { slug } = ownProps;
  return {
    item: selectItemBySlug(state, slug),
    user: selectUser(state),
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Item);
