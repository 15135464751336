// import { items } from "./items";
export const API_URL = process.env.REACT_APP_API_URL || "http://localhost:8080";

const initialState = {
  items: null,
  user: null,
  currentSiteUrl: "",
  filterBy: "type",
  filters: null,
  showingPage: "items",
  contactForm: {
    alertMessage: {},
    hasUnsentData: false,
  },
  theme: null,
  publicImages: null,
  topLinks: null, // Links in navbar
};

const actionTypes = {
  DATA_SUCCESS: "DATA_SUCCESS",
  SET_DATA: "SET_DATA",
  INIT_FILTERS: "INIT_FILTERS",
  SET_ACTIVE_FILTERS: "SET_ACTIVE_FILTERS",
  SET_CURRENT_SITE_URL: "SET_CURRENT_SITE_URL",
  SET_SHOWING_PAGE: "SET_SHOWING_PAGE",
  SET_PUBLIC_IMAGES: "SET_PUBLIC_IMAGES",
  SET_EMAIL_ALERT_MESSAGE: "SET_EMAIL_ALERT_MESSAGE",
  SET_THEME: "SET_THEME",
  SET_TOP_LINKS: "SET_TOP_LINKS",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.DATA_SUCCESS:
      return state;
    case actionTypes.SET_DATA:
      const items = action.payload;
      return {
        ...state,
        items: items,
        user: items.find((i) => i.isUser),
      };
    case actionTypes.SET_THEME:
      return {
        ...state,
        theme: action.payload,
      };
    case actionTypes.SET_PUBLIC_IMAGES:
      return {
        ...state,
        publicImages: action.payload,
      };
    case actionTypes.SET_CURRENT_SITE_URL:
      return {
        ...state,
        currentSiteUrl: action.payload,
      };
    case actionTypes.SET_TOP_LINKS:
      return {
        ...state,
        topLinks: action.payload,
      };
    case actionTypes.INIT_FILTERS:
      const filters = action.payload;
      const activeFilter = filters.filter((f) => f.default)[0].value;
      const initialFilters = filters.map((f) => {
        return {
          ...f,
          active: activeFilter === "all" ? true : f.default,
          highlighted: activeFilter === "all" ? f.value === "all" : f.default,
        };
      });
      return {
        ...state,
        filters: initialFilters,
      };
    case actionTypes.SET_ACTIVE_FILTERS:
      const allSelected = action.payload.value === "all";
      const newFilters = allSelected
        ? state.filters.map((f) => {
            return {
              ...f,
              active: true,
              highlighted: f.value === "all",
            };
          })
        : state.filters.map((f) => {
            const isActive = f.value === action.payload.value;
            return {
              ...f,
              active: f.value === action.payload.value,
              highlighted: allSelected ? f.value === "all" : isActive,
            };
          });
      return {
        ...state,
        filters: newFilters,
      };
    case actionTypes.SET_SHOWING_PAGE:
      return {
        ...state,
        showingPage: action.payload,
      };
    case actionTypes.SET_EMAIL_ALERT_MESSAGE: {
      return {
        ...state,
        contactForm: {
          ...state.contactForm,
          alertMessage: action.payload,
        },
      };
    }
    default:
      return state;
  }
};

const setData = (payload) => {
  return {
    type: actionTypes.SET_DATA,
    payload: payload,
  };
};

const setTheme = (payload) => {
  return {
    type: actionTypes.SET_THEME,
    payload: payload,
  };
};

const setPublicImages = (payload) => {
  return {
    type: actionTypes.SET_PUBLIC_IMAGES,
    payload: payload,
  };
};

export const setCurrentSiteUrl = (payload) => {
  return {
    type: actionTypes.SET_CURRENT_SITE_URL,
    payload: payload,
  };
};

const setFilters = (payload) => {
  return {
    type: actionTypes.INIT_FILTERS,
    payload: payload,
  };
};

const setTopLinks = (payload) => {
  return {
    type: actionTypes.SET_TOP_LINKS,
    payload: payload,
  };
};

export const setShowingPage = (payload) => {
  return {
    type: actionTypes.SET_SHOWING_PAGE,
    payload: payload,
  };
};

export const setEmailAlertMessage = (payload) => {
  return {
    type: actionTypes.SET_EMAIL_ALERT_MESSAGE,
    payload: payload,
  };
};

export const setActiveFilters = (payload) => {
  return {
    type: actionTypes.SET_ACTIVE_FILTERS,
    payload: payload,
  };
};

// GET PROJECTS ON INITIAL LOAD
export const fetchData = (currentSiteUrl) => {
  return (dispatch) => {
    fetch(`${API_URL}/items?url=${currentSiteUrl}`, {
      mode: "cors",
    })
      .then((d) => d.json())
      .then((data) => {
        // console.log("Response", data);
        dispatch(setData(data.items));
        dispatch(setTheme(data.theme));
        dispatch(setFilters(data.filters));
        dispatch(setPublicImages(data.public_images));
        dispatch(setCurrentSiteUrl(currentSiteUrl));
        dispatch(setTopLinks(data.topLinks));
      });
  };
};

// SEND EMAIL
export const sendEmail = (emailData, userId, currentSiteUrl) => {
  const data = {
    data: emailData,
    user: { id: userId },
    site: { url: currentSiteUrl },
  };
  console.log("sending", data);
  return (dispatch) => {
    console.log("data hre", JSON.stringify(data));
    fetch(`${API_URL}/email`, {
      mode: "cors",
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((d) => d.json())
      .then((data) => {
        dispatch(setEmailAlertMessage(data));
      })
      .catch(() => {
        dispatch(
          setEmailAlertMessage({
            success: false,
            message: "Something went wrong",
          })
        );
      });
  };
};

///// SELECTORS /////

export const selectAllFilters = (state) => {
  return state.allFilters;
};

export const selectCurrentSiteUrl = (state) => {
  return state.currentSiteUrl;
};

export const selectFilters = (state) => {
  return state.filters;
};

export const selectTopLinks = (state) => {
  return state.topLinks;
};

export const selectItemBySlug = (state, slug) => {
  return state.items.find((item) => item.slug === slug);
};

export const selectEmailAlertMessage = (state) => {
  return state.contactForm.alertMessage;
};

export const selectTheme = (state) => {
  return state.theme;
};

export const selectItems = (state) => {
  return state.items;
};

export const selectUser = (state) => {
  return state.user;
};

export const selectPublicImages = (state) => {
  return state.publicImages;
};

export const selectSocialLinks = (state) => {
  const { user, items } = state;
  const icons = state.publicImages;
  if (!icons) return [];
  const { tags } = user;
  const links = tags.filter((t) => Object.keys(t)[0] === "link");
  const externalLinks = links
    .map((link) => {
      const linkId = Object.values(link)[0];
      return items.find((i) => i.id === linkId);
    })
    .filter((i) => i);

  return externalLinks.map((link) => {
    return {
      ...link,
      image: icons.find((i) => i.id === link.image),
    };
  });
};

export const selectActiveItems = (state) => {
  const activeFilters = state.filters.filter((f) => f.active);
  const items = state.items || [];

  const activeItems = items.filter((item) => {
    return activeFilters.some((filter) => {
      return (
        item[filter.parentCategory] &&
        (item[filter.parentCategory][filter.value] ||
          item[filter.parentCategory].includes(filter.value))
      );
    });
  });
  return activeItems;
};

export default reducer;
