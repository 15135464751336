import React from "react";
import "./social-links.css";

const SocialLinks = ({ links }) => {
  return (
    <div className="social-links">
      {links.map((link, i) => {
        return (
          <a
            key={i}
            className="social-link"
            href={link.externalLink}
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="social-link-logo"
              height={30}
              width={"auto"}
              title={link.title}
              alt={link.image.title}
              src={`https://graphsby.s3-ap-southeast-2.amazonaws.com/public/${link.image.id}.svg`}
            ></img>
          </a>
        );
      })}
    </div>
  );
};

export default SocialLinks;
