import React from "react";
import "./LoadingNotice.css";

function LoadingNotice({ loadingText }) {
  return (
    <div className="loading-notice">
      <div>
        <img
          src="image/loading.gif"
          className="loading-notice-spinner"
          alt="loading"
        />
      </div>
      <div>
        <span>{loadingText}</span>
      </div>
    </div>
  );
}

export default LoadingNotice;
