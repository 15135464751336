import React from "react";
import "./profile.css";
import { connect } from "react-redux";
import {
  selectPublicImages,
  selectSocialLinks,
  selectTheme,
  selectUser,
} from "../../ducks";
import SocialLinks from "../social-links";

function createMarkup(html) {
  return { __html: html };
}

const Profile = ({ theme, user, icons, links }) => {
  return (
    <div>
      <div className="photo">
        <img
          src={`https://graphsby.s3-ap-southeast-2.amazonaws.com/${user.slug}/${user.image}.jpg`}
          rel="foaf:depiction"
          alt={user.title}
        />
      </div>

      <div id="info-text">
        <div id="title-box">
          <p id="name-header">{user.title}</p>
        </div>
        {user.subTitle && (
          <p style={{ textAlign: "center" }}>{user.subTitle}</p>
        )}

        <div
          className="user-added-text"
          style={{ fontWeight: 200 }}
          dangerouslySetInnerHTML={createMarkup(user.description)}
        />

        <SocialLinks links={links} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    theme: selectTheme(state),
    user: selectUser(state),
    icons: selectPublicImages(state),
    links: selectSocialLinks(state),
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
