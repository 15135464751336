import React from "react";
import "./navbar.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  setShowingPage as _setShowingPage,
  selectUser,
  selectTheme,
  selectTopLinks,
} from "../../ducks";

const Navbar = ({ setShowingPage, user, theme, topLinks }) => {
  const { backgroundColor, color } = theme.navbar;
  return (
    <div
      className="main-header"
      style={{ backgroundColor: backgroundColor, color: color }}
    >
      <p
        id="site-header"
        onClick={() => {
          setShowingPage("items");
        }}
      >
        <Link to={{ pathname: "/" }}>{user.title}</Link>
      </p>
      <div className="menu-items">
        {topLinks &&
          topLinks.map((toplink, i) => {
            const { label, link } = toplink;
            return (
              <div className="menu-item" key={i}>
                <Link to={{ pathname: link }}>{label}</Link>
              </div>
            );
          })}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: selectUser(state),
    theme: selectTheme(state),
    topLinks: selectTopLinks(state),
  };
};

const mapDispatchToProps = {
  setShowingPage: _setShowingPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
